import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
//import HelpCenterGrid from "../components/HelpCenterGrid.js.js";

import { helpCenterFeaturedContent, faqsHeroDetails } from "../constants";
import HelpCenterGridv2 from "../components/HelpCenterGridv2";

const HelpCentre = () => {
  const data = useStaticQuery(graphql`
    query {
      featuredContentImages: allFile(
        filter: {
          name: { in: ["01-eicr-pat-services", "05-alfie-barnard", "06-eicr-pat-services"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      heroImage: allFile(filter: { name: { in: "hero-faqs" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  // faqs structured data - to do
  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "How often should the electrics in my home be checked?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The Electrical installation within your home should be inspected and tested by having an EICR (electrical installation condition report) carried out every 10 years for a owner occupied home and every 5 years for a rented home.</p>"
        }
      }, {
        "@type": "Question",
        "name": "What are the signs that a circuit breaker needs replacing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The circuit breaker trips frequently. There is a burning smell coming from the fuse board. The circuit breaker fails to stay in the on position. There are physical signs of damage to the circuit breaker. Light fittings or appliances that are connected to the circuit breaker start to drop in performance."
        }
      }, {
        "@type": "Question",
        "name": "Can the electrics in my home cause a fire?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, fires are mainly caused by overloading a circuit but a perfectly working circuit breaker should protect against that, also poor or loose connections within a circuit can cause arcing and heat build up which could cause a fire."
        }
      }, {
        "@type": "Question",
        "name": "How do I find a good electrician?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You already have, our contact details are on this website. Intact Electrical Ltd are reliable, professional, registered and approved electrical contractors and installers, with decades of invaluable knowledge. We provide every client with quality services and an exceptional customer experience."
        }
      }, {
        "@type": "Question",
        "name": "How often should commercial premises have their electrics tested?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Commercial premises should be inspected and tested by having an EICR (electrical installation condition report) carried out a minimumm of every 5 years."
        }
      }, {
        "@type": "Question",
        "name": "Why should I have the electrics within my commercial premises checked?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Faulty or old electrical systems can cause fires and damage to your property and appliances, so it's definitely a good idea to have them assesses regularly."
        }
      }, {
        "@type": "Question",
        "name": "How can I maintain my commercial or industrial electrical installation?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can maintain safe and compliant working electrics by having then regularly inspected and tested, ensuring you have an up to date EICR (Electrical Installation Condition Report)."
        }
      }, {
        "@type": "Question",
        "name": "Who could maintain my commercial or industrial electrical installation to ensure that it's safe and complies with current regulations?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Intact Electrical can offer you a complete maintenance package for any type or size of electrical installation that you require, keeping you, your property and employees electrically safe and compliant with current regulations."
        }
      }]
    })}
    </script>
  )

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = faqsHeroDetails;

  return (
    <>
      <Seo       
        title="Help center - FAQs"
        description="Intact Electrical, Help centre, FAQs, Frequently asked questions, ask the experts, General domestic commercial industrial electrical FAQs"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={helpCenterFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <HelpCenterGridv2 />
      {/* <HelpCenterGrid /> */}
      {/* <JobList /> */}
      {/* <PageAdvert ad={burnhamFootballClub} image={burnhamFootballClubImage} />
      <PageAdvert ad={sloughInBloom} image={sloughInBloomImage} />
      <PageAdvert ad={hsPepper} image={hsPepperImage} /> */}
    </>
  );
};

export default HelpCentre;
