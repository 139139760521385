import React, { useState } from "react";

import { domesticQuestions, commercialQuestions } from "../constants";
import SingleQuestion from "./SingleQuestion";
import Headings from "./Headings";

import styles from "../styles/style";

const HelpCenterGridv2 = () => {
  const [cards] = useState(domesticQuestions);
  const [commercialCards] = useState(commercialQuestions);

  return (
    <div className={`bg-primary mb-4 ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Headings
          titleTop="Frequently asked"
          titleBottom="questions"
          description="Whether your electrical project is domestic, commercial, or industrial, Intact Electrical have a solution to meet your needs. Always aiming for 100% customer satisfaction, and right first time every time."
        />

        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 mt-4">
        <section className="grid grid-cols-1 gap-8 mb-6 md:mb-0">
          <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]">Domestic FAQs</h2>
          {cards.map((card, index) => (
            <SingleQuestion {...card} key={index} />
          ))}
        </section>
        <section className="grid grid-cols-1 gap-8">
        <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]">Commercial & Industrial FAQs</h2>
          {commercialCards.map((commercialCard, index) => (
            <SingleQuestion {...commercialCard} key={index} />
          ))}
        </section>
        </div>
        
      </div>
    </div>
  );
};

export default HelpCenterGridv2;
